import linkImg1 from '../assets/images/link-img-1.png'
import linkImg2 from '../assets/images/link-img-2.png'
import linkImg3 from '../assets/images/link-img-3.png'
import linkImg4 from '../assets/images/link-img-4.png'
import linkImg5 from '../assets/images/link-img-5.png'

export const skills = [
  'JavaScript',
  'React.js',
  'TypeScript',
  'State management',
  'Server-Side Rendering',
  'GraphQL',
  'Next.js',
  'Gatsby.js',
  'React Native',
  'HTML(5)',
  'CSS(3)',
  'SASS, LESS',
  'Custom Shopify Themes',
  'Webflow',
  'Git version control',
  'Project management',
  'SCRUM and Jira, Asana, Trello management tools',
  'Graphic design',
  'Computer animation',
  'UX / UI',
  'Figma',
  'Zeplin, InVision, Marvel prototyping tools',
]

export const hobby = [
  {
    id: '01',
    title: 'Colorized.mk',
    url: 'https://colorized.mk/',
    img: `${linkImg1}`,
  },
  {
    id: '02',
    title: 'Butik EMF',
    url: 'https://butikfestival.com/',
    img: `${linkImg2}`,
  },
  {
    id: '03',
    title: 'Lana Sos',
    url: 'https://lana-sos.com/',
    img: `${linkImg3}`,
  },
  {
    id: '04',
    title: 'Andrea Iris',
    url: 'https://andreairis.netlify.app/',
    img: `${linkImg4}`,
  },
  {
    id: '05',
    title: 'Playground',
    url: 'https://github.com/jovanovskajovana/playground',
    img: `${linkImg5}`,
  },
]

export const work = [
  {
    title: 'Routable',
    url: 'https://routable.com/',
  },
  {
    title: 'Room',
    url: 'https://room.com/',
  },
  {
    title: 'MintAndSquare',
    url: 'https://mintandsquare.com/',
  },
  {
    title: 'LLoveFoundation',
    url: 'https://llovefoundation.org/',
  },
  {
    title: 'SantosEstate',
    url: 'https://www.santosestate.com/',
  },
  {
    title: 'Reemina',
    url: 'https://www.reemina.com/',
  },
  {
    title: 'Praiskon Hacienda',
    url: 'https://hkpraiskon.com/',
  },
  {
    title: 'Resore',
    url: 'https://www.resore.com/',
  },
  {
    title: 'GlobaliD',
    url: 'https://global.id/',
  },
  {
    title: 'Archie',
    url: 'https://archie.finance/',
  },
  {
    title: 'Specto',
    url: 'https://www.specto.dev/',
  },
  {
    title: '1Home',
    url: 'https://www.1home.io/',
  },
  {
    title: 'Plume',
    url: 'https://www.plume.com/',
  },
  {
    title: 'CE.way',
    url: 'https://ceway.eu/',
  },
  {
    title: 'SerenaStore',
    url: 'https://www.sbyserena.com/',
  },
  {
    title: 'Shoobs',
    url: 'https://shoobs.com/',
  },
]

export const writing = [
  {
    title: 'GiD Design System',
    url: 'https://globalid.gitlab.io/common/design-system',
  },
  {
    title: 'GiD Forms',
    url: 'https://globalid.github.io/web-apps/?path=/story/forms--page',
  },
  {
    title: 'Archie Design System',
    url: 'https://archie-design-system.netlify.app',
  },
  {
    title: 'React Styleguides',
    url: 'https://github.com/jovanovskajovana/styleguides/tree/master/react',
  },
  {
    title: 'JavaScript Styleguides',
    url: 'https://github.com/jovanovskajovana/styleguides/tree/master/javascript',
  },
  {
    title: 'Slicing Styleguides',
    url: 'https://github.com/jovanovskajovana/styleguides/tree/master/slicing',
  },
  {
    title: 'Gatsby Template',
    url: 'https://github.com/jovanovskajovana/gatsby-starter-styled-components',
  },
  {
    title: 'CSS Grid vs Flexbox',
    url: 'https://github.com/jovanovskajovana/flexbox-vs-cssgrid',
  },
]
